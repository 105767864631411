<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- Header -->
        <section class="header stores">
            <div class="icon iconButton"></div>
            <span class="fL">優惠好康</span>
            <NotificationBell
                @count-unread="countUnread = $event"
            />
        </section>
        <!-- Main -->
        <section class="main stores">
            <!-- Tabs -->
            <div class="tabs line fM rounded">
                <!-- <a class="tab" @click.prevent="">
                    <span class="fM">優惠券</span>
                </a> -->
                <!-- <div class="lineV"></div> -->
                <a class="tab active" @click.prevent="$router.push('/stores')">
                    <span class="fM">特店列表</span>
                </a>
                <div class="lineV"></div>
                <a class="tab" @click.prevent="$router.push('/map')">
                    <span class="fM">特店地圖</span>
                </a>
            </div>
            <!-- Filter -->
            <div class="filter flexH width">
                <div class="flexH width middle">
                    <div class="dropdown rounded">
                        <select name="type" class="fM rounded" v-model="filterCategory" @change="filterHandler">
                            <option value="0">分類</option>
                            <option v-for="(category, index) in categories" :key="'category'+index" :value="category.id">{{category.name}}</option>
                        </select>
                        <div class="arrow third"><font-awesome-icon icon="fas fa-caret-down" size="xs"></font-awesome-icon></div>
                    </div>
                </div>
                <div class="lineV"></div>
                <div class="flexH width middle">
                    <div class="dropdown rounded">
                        <!-- <select name="type" class="fM rounded" v-model="filterRegion" @change="filterHandler"> -->
                        <select name="type" class="fM rounded" v-model="filterRegion" @change="getStores(filterRegion)">
                            <option value="-1">地區</option>
                            <option v-for="(region) in regions" :value="region.id" :key="region.id">{{region.name}}</option>
                        </select>
                        <div class="arrow third"><font-awesome-icon icon="fas fa-caret-down" size="xs"></font-awesome-icon></div>
                    </div>
                </div>
                <!-- <div class="lineV"></div> -->
                <!-- <div class="flexH width middle">
                    <div class="dropdown rounded">
                        <select name="type" class="fM rounded">
                            <option value="排序">排序</option>
                            <option value="排序">排序</option>
                        </select>
                        <div class="arrow third"><font-awesome-icon icon="fas fa-caret-down" size="xs"></font-awesome-icon></div>
                    </div>
                </div> -->
            </div>
            <!-- List -->
            <div class="list flexV width">
                <!-- 搜尋 -->
                <div class="input rounded">
                    <input type="text" class="fM" placeholder="搜尋" v-model="filterName" @keypress.enter="filterHandler">
                    <a class="icon iconButton" @click.prevent="filterHandler"><font-awesome-icon icon="fas fa-search" size="lg"></font-awesome-icon></a>
                </div>
                <!-- 特店資訊 -->
                <a class="information flexH width between" @click.prevent="$router.push(`/stores/${store.id}/info`)" v-for="(store, index) in displayStores" :key="'store'+index">
                    <!-- class: hide -->
                    <div class="flexV">
                        <span class="fL">{{store.name}}</span>
                        <span class="fM gray">{{store.address}}</span>
                        <div class="detail flexH">
                            <div class="img point"><img src="@/assets/icon/point.svg"></div>
                            <span class="fS gray">紅利桃子</span>
                            <!-- <span class="fS gray">・</span>
                            <div class="img ticket"><img src="@/assets/icon/ticket.svg"></div>
                            <span class="fS gray">優惠券</span>
                            <span class="fS gray">・</span>
                            <div class="img stamp"><img src="@/assets/icon/stamp.svg"></div>
                            <span class="fS gray">集章</span> -->
                        </div>
                    </div>
                    <img class="store" src="@/assets/img/store.png">
                </a>
                
            </div>
        </section>
    </div>
</template>
<script>
import NotificationBell from "@/components/NotificationBell.vue";

export default {
    name: "Stores",
    components: {
        NotificationBell
    },
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            storeApiHost: process.env.VUE_APP_STORE_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            stores: [],
            displayStores: [],
            countUnread: 0,
            categories: [],
            filterCategory: 0,
            filterRegion: -1,
            regions: [],
            filterName: ""
        };
    },
    async mounted() {
        await this.getStores()
        this.getCategories()
        this.getZones()
        // this.generateRegions()
    },
    methods: {
        dummyStores() {
            let response = [
                {
                    "name": "測試商店",
                    "address": "測試地址",
                    "phone": "0987654321",
                    "banId": "85111267",
                    "storePlaceId": "ChIJx3Qv-KchaDQRA1lVKGSYsVQ",
                    "geometry": JSON.stringify({
                        "location": {
                            "lat": 25.0109536,
                            "lng": 121.2173832
                        },
                        "viewport": {
                            "south": 25.0100204697085,
                            "west": 121.2164427697085,
                            "north": 25.0127184302915,
                            "east": 121.2191407302915
                        }
                    }),
                    "categoryName": "餐飲",
                    "categoryId": 1,
                    "addressComponents": JSON.stringify([
                        {
                            "long_name": "9",
                            "short_name": "9",
                            "types": [
                                "street_number"
                            ]
                        },
                        {
                            "long_name": "航站南路",
                            "short_name": "航站南路",
                            "types": [
                                "route"
                            ]
                        },
                        {
                            "long_name": "大園區",
                            "short_name": "大園區",
                            "types": [
                                "administrative_area_level_3",
                                "political"
                            ]
                        },
                        {
                            "long_name": "桃園市",
                            "short_name": "桃園市",
                            "types": [
                                "administrative_area_level_1",
                                "political"
                            ]
                        },
                        {
                            "long_name": "台灣",
                            "short_name": "TW",
                            "types": [
                                "country",
                                "political"
                            ]
                        },
                        {
                            "long_name": "33758",
                            "short_name": "33758",
                            "types": [
                                "postal_code"
                            ]
                        }
                    ])
                },
                {
                    "name": "qwe",
                    "address": "asdf",
                    "phone": "321564897",
                    "banId": "85111246",
                    "storePlaceId": "ChIJ1RXSYsCfQjQRCbG1qZC2o3A",
                    "geometry": JSON.stringify({
                        "location": {
                            "lat": 25.0796514,
                            "lng": 121.234217
                        },
                        "viewport": {
                            "south": 25.05372919999999,
                            "west": 121.2032256,
                            "north": 25.10275000000001,
                            "east": 121.2624304
                        }
                    }),
                    "categoryName": "餐飲",
                    "categoryId": 1,
                    "addressComponents": JSON.stringify([
                        {
                            "long_name": "9",
                            "short_name": "9",
                            "types": [
                                "street_number"
                            ]
                        },
                        {
                            "long_name": "航站南路",
                            "short_name": "航站南路",
                            "types": [
                                "route"
                            ]
                        },
                        {
                            "long_name": "大大區",
                            "short_name": "大大區",
                            "types": [
                                "administrative_area_level_3",
                                "political"
                            ]
                        },
                        {
                            "long_name": "桃園市",
                            "short_name": "桃園市",
                            "types": [
                                "administrative_area_level_1",
                                "political"
                            ]
                        },
                        {
                            "long_name": "台灣",
                            "short_name": "TW",
                            "types": [
                                "country",
                                "political"
                            ]
                        },
                        {
                            "long_name": "33758",
                            "short_name": "33758",
                            "types": [
                                "postal_code"
                            ]
                        }
                    ])
                }
            ];
            let promise = new Promise((resolve) => {
                setTimeout(() => {
                    resolve({data: response});
                }, 100);
            });
            return promise;
        },
        // callStoresAPI() { // deprecated
        //     let config = {
        //         url: `${this.storeApiHost}/stores/v1/merchants/${this.merchantId}/stores`,
        //         method: "get"
        //     };

        //     return this.$http(config)
        // },
        getStores(zoneId) {
            // return this.callStoresAPI()
            // return this.dummyStores()
            // console.log('get stores ', query);
            let config = {
                url: `${this.storeApiHost}/stores/v1/merchants/${this.merchantId}/portals/tyapp/stores`,
                method: "get"
            };
            if (typeof zoneId !== 'undefined' && zoneId !== '-1') {
                config.params = {zone_id: zoneId}
            }

            return this.$http(config)
            .then((response) => {
                console.log("response: ", response);
                this.stores = JSON.parse(JSON.stringify(response.data))
                this.stores.forEach(store => {
                    if (store.geometry) {
                        store.geometry = JSON.parse(store.geometry);
                    }
                    if (store.addressComponents) {
                        store.addressComponents = JSON.parse(store.addressComponents);
                    }
                })
                
                console.log("this.stores: ", this.stores)
                this.displayStores = JSON.parse(JSON.stringify(this.stores))
                return;
            });
        },
        callCategoriesAPI() {
            let config = {
                url: `${this.storeApiHost}/stores/v1/merchants/${this.merchantId}/categories`,
                method: "get"
            };

            return this.$http(config)
        },
        getCategories() {
            this.callCategoriesAPI()
            .then((response) => {
                console.log("response: ", response);
                this.categories = response.data;
            });
        },
        getZones() {
            let config = {
                url: `${this.storeApiHost}/stores/v1/merchants/${this.merchantId}/portals/tyapp/zones`,
                method: "get"
            };

            return this.$http(config)
            .then((response) => {
                console.log("zone response: ", response);
                this.regions = response.data;
            });
            
        },
        filterHandler() {
            let displayStores = JSON.parse(JSON.stringify(this.stores));
            console.log(displayStores)
            displayStores = this.filterByCategory(displayStores);
            console.log(displayStores)
            // displayStores = this.filterByRegion(displayStores);
            // console.log(displayStores)
            displayStores = this.filterByName(displayStores);
            console.log(displayStores)
            this.displayStores = displayStores;

        },
        filterByCategory(displayStores) {
            if(this.filterCategory == 0) return displayStores;
            displayStores = displayStores.filter(store => {
                return store.categoryId == this.filterCategory;
            });
            return displayStores;
        },
        // filterByRegion(displayStores) {
        //     // TBD: 改成呼叫後端
        //     if(this.filterRegion == -1) return displayStores;
        //     displayStores = displayStores.filter(store => {
        //         let addressComponents = store.addressComponents;
        //         if(addressComponents) {
        //             return this.regions[this.filterRegion] == addressComponents[addressComponents.length - 4].long_name;
        //         } else {
        //             return false;
        //         }
        //     });
        //     return displayStores;
        // },
        filterByName(displayStores) {
            if(!this.filterName) return displayStores;
            displayStores = displayStores.filter(store => {
                return store.name.indexOf(this.filterName) != -1
            });
            return displayStores;
        },
        // generateRegions() {
        //     let regions = this.stores.filter(store => store.addressComponents).map(store => {
        //         let addressComponents = store.addressComponents
        //         if(addressComponents) {
        //             return addressComponents[addressComponents.length - 4].long_name;
        //         } else {
        //             return false;
        //         }
        //     });
        //     this.regions = Array.from(new Set(regions));
        // }
    }
};
</script>
